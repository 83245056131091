import React, {useContext, useState} from "react";
import "./Home.scss";
import { ModelContext } from "providers/ModelProvider";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import imgContent from 'img-test';

function Home() {
  const [ score,setScore ]=useState(0);
  const { appState,setAppState,post } = useContext(ModelContext);
  const { city, user, playgroundId, status, leaderBoard, userId } = appState;
  const handleEndGame=()=>{
    post('room/end',{user,score},(data)=>{
      const {leaderBoard,userId} = data;
      if (leaderBoard && leaderBoard.length>0) setAppState(state=>{return{...state,leaderBoard}});
      if (userId) {
        setAppState(state=>{return{...state,userId}});
        handleSendPicture(userId);
      }
    });
  }
  const handleClear=()=>{
    post('room/clear',{},(data)=>{
      console.log(data);
    });
  }
  const handleSendPicture=(userId)=>{
    post('room/selfie',{userId,picture:imgContent.content},(data)=>{
      console.log(data);
    });
  }
  return <div className="home">
    <h1 className="city">
      {city}
    </h1>
    <div className="spacer"/>
    <Button variant='contained' onClick={handleClear}>Vider la liste d'attente</Button>
    <div className="spacer"/>
    <div className="status">
      {status}
    </div>
    {user && <>
      {playgroundId && <div className="playground">
        Playground n°{playgroundId}
      </div>}
      <div className="spacer"/>
      <div className="user">
        {Object.keys(user).map((k)=><div key={k} className='user-key'>
          <div className="key">{k}</div>
          <div className="value">{typeof user[k] == "boolean" ? (user[k] ? 'true' : 'false') : user[k]}</div>
        </div>)}
      </div>
      </>
    }
    <div className="spacer"/>
    {status==='play' && <div className="action">
      <Stack>
        <TextField
        autoFocus
        label='Score'
        value={score || 0}
        onChange={(e)=>setScore(parseInt(e.target.value))}
        onKeyPress={(e)=>e.which===13 && handleEndGame()}
        />
        <div className="spacer"/>
        <Button variant='contained' onClick={handleEndGame}>End</Button>
      </Stack>
    </div>}
    {leaderBoard && leaderBoard.length>0 && <table className="leaderboard">
      <thead>
        <tr><th>UserName</th><th className="score">Score</th></tr>
      </thead>
      <tbody>
      {leaderBoard.map((o)=><tr key={o.id} className={o.id===userId ? 'active' : ''}>
        <td>{o.username}</td><td className="score">{o.score}</td>
      </tr>)}
      </tbody>
    </table>}
  </div>;
}

export default Home;
